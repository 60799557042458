import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import PageMetaData from 'components/PageMetaData';
import {MultiGrid, RequesterViewType} from 'components/MultiGrid';
import {CardActionType} from 'components/MultiGrid';
import * as DashboardStore from 'stores/Dashboard';

@withRouter
@connect((state) => ({user: state.user,dashboard: state.dashboard}), DashboardStore.actionCreators)
export default class Loaners extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...DashboardStore.ActionShape,
        dashboard: DashboardStore.StateShape,
    };

    constructor(props) {
        super(props);

        const {dashboard: {myAccountFilter}} = props;
        this.state = {
            gridName: 'loanerColumns',
            columns: [],
            view: 'Grid',
            orders: [],
            loading: true,
            requesterViewType: myAccountFilter.lineItemIds.length > 0
                ? RequesterViewType.AllRequesters
                : RequesterViewType.CurrentUser,
        }
    }
    

    componentDidMount() {
        const { gridName } = this.state;
        const { requesterViewType } = this.state;
        const { user: { settings: { showAllRecords } } } = this.props;
        const showAllOrders = requesterViewType === RequesterViewType.AllRequesters;
        axios.get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`).then(x => {
            this.setState({columns: x.data.columns, view: x.data.view});
        });
        this.getData(showAllOrders);
    }

    getData(showAllRecords) {
        const {dashboard: {myAccountFilter}, resetMyAccountFilter} = this.props;
        this.setState({loading: true});
        let request = {
            showAllOrders: true,
            lineItemIds: myAccountFilter.lineItemIds,
        };
        axios.post('/ShoppingService/api/v1/orders/loaner', request).then(x => {
            if (myAccountFilter.lineItemIds) resetMyAccountFilter();
                x.data.filter(x => x.groupName === "ShippedOutbound" || x.groupName === "Returned").forEach(d => {
                    d.trackingNumber = d.customerTrackingNumber;
            });
            this.setState({orders: x.data, loading: false});
        });
    }

    handleSelect(item) {
        const {history} = this.props;
        history.push(`/order/${item.lineItemId}`);
    }

    handleRequesterToggle(requesterViewType) {
        this.setState({requesterViewType})
        this.getData(requesterViewType === RequesterViewType.AllRequesters);
    }

    render() {
        const {gridName, columns, orders, view, loading, requesterViewType} = this.state;

        let gridKeys = [
            {
                id: 'requested',
                fieldName: 'groupName',
                value: 'Requested',
                tabDisplay: 'Requested',
                height: '560',
            },
            {
                id: 'quoted',
                fieldName: 'groupName',
                value: 'Quoted', 
                tabDisplay: 'Quoted',
                height: '560',
                cardAction: CardActionType.LoanerQuoted
            },
            {
                id: 'shippedToYou',
                fieldName: 'groupName',
                value: 'ShippedInbound',
                tabDisplay: 'Shipped Inbound',
                height: '560',
            },
            {
                id: 'onLoan',
                fieldName: 'groupName',
                value: 'OnLoan', 
                tabDisplay: 'On Loan',
                height: '560',
                cardAction: CardActionType.LoanerReturn
            },
            {
                id: 'shippedToVendor',
                fieldName: 'groupName',
                value: 'ShippedOutbound',
                tabDisplay: 'Shipped Outbound',
                height: '560',
            },
            {
                id: 'returned',
                fieldName: 'groupName',
                value: 'Returned', 
                tabDisplay: 'Returned',
                height: '560',
            },
        ];

        return (<div>
            <PageMetaData
                title="Loaner Orders"
                pageType="other"
                trackAnalytics={true}
            />
            <MultiGrid
                onRequesterToggle={::this.handleRequesterToggle}
                showRequesterToggle={true}
                gridName={gridName}
                view={view}
                gridKeys={gridKeys}
                data={orders}
                columns={columns}
                label="My Account"
                subLabel="Loaners"
                onRefresh={::this.getData}
                onSelect={::this.handleSelect}
                loadingData={loading}
                requesterViewType={requesterViewType}
            />
        </div>)
    }
}
